import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import uz from "assets/translations/uz.json";
import ru from "assets/translations/ru.json";
import en from "assets/translations/en.json";
import qr from "assets/translations/qr.json";

const resources = {
  uz: {
    translation: uz,
  },
  ru: {
    translation: ru,
  },
  en: {
    translation: en,
  },
  qr: {
    translation: qr,
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("_lang") || "uz",
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
