import React, { Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";

// Lazy load route components
const Home = React.lazy(() => import("./pages/Home"));
const Checkout = React.lazy(() => import("./pages/Checkout"));
const Error = React.lazy(() => import("./pages/Error"));
const Layout = React.lazy(() => import("./layout/Layout"));

// Loader component
const Loader = () => <div>Loading...</div>;

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<Loader />}>
        <Layout>
          <Home />
        </Layout>
      </Suspense>
    ),
  },
  {
    path: "/checkout",
    element: (
      <Suspense fallback={<Loader />}>
        <Layout>
          <Checkout />
        </Layout>
      </Suspense>
    ),
  },
  {
    path: "/error",
    element: (
      <Suspense fallback={<Loader />}>
        <Layout>
          <Error />
        </Layout>
      </Suspense>
    ),
  },
]);
