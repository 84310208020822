import React from "react";
import ReactDOM from "react-dom/client";
// i18n
import "./services/i18n";
// router
import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
// css
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <RouterProvider router={router} />
  // </React.StrictMode>
);
